'use client'

import { removeLoading } from '@utils'
import { get } from 'lodash-es'
import Image, { getImageProps } from 'next/image'

export default function ImageBgLoading({
  src,
  width,
  height,
  alt = '',
  ...args
}) {

  return (
    <Image src={src || ''} width={width} height={height} alt={alt} {...args} imgbg='loading' onLoad={removeLoading} onError={removeLoading} />
  )
}

export function ResponsiveImageBgLoading({
  src,
  mobileSrc,
  width,
  height,
  mobileWidth = width,
  mobileHeight = height,
  alt = '',
  className,
  wrapClassName,
  useLoading = true,
  priority = false,
  origin = false,
  unoptimized,
  ...args
}) {

  if (!src && !mobileSrc) return

  const url = src || mobileSrc || ''
  const murl = mobileSrc || src || ''
  const isSvg = /.svg$/i.test(get(url, 'src', url))

  const common = { alt, unoptimized: isSvg || unoptimized, priority }
  const { props: { srcSet: desktop, ...rest } } = getImageProps({ ...common, src: url, width, height })
  const { props: { srcSet: mobile, ...mrest } } = getImageProps({ ...common, src: murl, width: mobileWidth, height: mobileHeight })

  return (
    <picture className={wrapClassName}>
      {
        origin
          ? (
            <>
              <source media="(min-width: 640px)" srcSet={get(url, 'src', url)} />
              <source media="(max-width: 639px)" srcSet={get(murl, 'src', murl)} />
            </>
          )
          : (
            <>
              <source media="(min-width: 640px)" srcSet={isSvg ? get(url, 'src', url) : desktop} {...rest} />
              <source media="(max-width: 639px)" srcSet={isSvg ? get(murl, 'src', murl) : mobile} {...mrest} />
            </>
          )
      }
      <Image
        {...rest}
        {...args}
        unoptimized={unoptimized}
        alt={alt}
        className={className}
        {...(useLoading ? { imgbg: 'loading' } : undefined)}
        onLoad={removeLoading}
        onError={removeLoading}
      />
    </picture>
  )
}